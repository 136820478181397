export default {
  variants: {
    solid: {
      color: 'black',
    },
  },
  defaultProps: {
    colorScheme: 'green',
  },
};
