/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-console */
import { useMemo } from 'react';

import { from, ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError, ErrorHandler } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { GetServerSidePropsContext } from 'next';
import { getSession } from 'next-auth/react';

import { CustomSession } from '@/server/models/auth';
import log from '@/services/log';

let apolloClient: ApolloClient<any>;

type GetAuthToken = () => Promise<string | null>;

export type ApolloClientContext = GetServerSidePropsContext;
type Logout = () => Promise<void>;

function createApolloClient(
  ctx?: ApolloClientContext,
  getAuthToken?: GetAuthToken,
  logout?: Logout
) {
  const coreApiLink = createUploadLink({
    uri: `${process.env.NEXT_PUBLIC_CORE_API}`,
  });
  const coreApiAuthLink = setContext(async (_, { headers }) => {
    let authToken: string;

    if (ctx && ctx.req) {
      const session = (await getSession({ req: ctx.req })) as CustomSession;
      authToken = session?.idToken;
    } else if (getAuthToken) {
      authToken = await getAuthToken();
    } else {
      return;
    }

    return {
      headers: {
        ...headers,
        Authorization: authToken,
      },
    };
  });

  const baseErrorHandler: ErrorHandler = ({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(async ({ message }) => {
        log.error(`[GraphQL error]: Message: ${message}`);
      });
    if (networkError) log.error(`[Network error]: ${networkError}`);
  };

  const coreApiErrorLink = onError((e) => {
    if (
      e.networkError &&
      'statusCode' in e.networkError &&
      e.networkError.statusCode === 401
    ) {
      if (logout) {
        logout();
      }
      return;
    }
    baseErrorHandler(e);
  });

  const coreApiLinks = from([coreApiAuthLink, coreApiErrorLink, coreApiLink]);

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: coreApiLinks,
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(
  ctx?: ApolloClientContext, // passed only during SSR
  initialState: any = null,
  getAuthToken: GetAuthToken = undefined
) {
  const ac = apolloClient ?? createApolloClient(ctx, getAuthToken);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = ac.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    ac.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return ac;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = ac;
  }

  return ac;
}

export const getApolloClient = initializeApollo;

export function useApollo(initialState: any, getAuthToken?: GetAuthToken) {
  const store = useMemo(
    () => initializeApollo(null, initialState, getAuthToken),
    [initialState, getAuthToken]
  );
  return store;
}
