export default {
  green: {
    50: '#e0fef3',
    100: '#b8f8db',
    200: '#8ef1c1',
    300: '#62ebb2',
    400: '#3ae6a7',
    500: '#24cc99',
    600: '#199f80',
    700: '#0e7163',
    800: '#024440',
    900: '#001915',
  },
  black: '#000000',
};
