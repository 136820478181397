import { useCallback } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
import { getSession, SessionProvider } from 'next-auth/react';
import Head from 'next/head';

import config from '@/config';
import { AuthProvider } from '@/context/AuthContext';
import { useApollo } from '@/graphql/apollo';
import { CustomSession } from '@/server/models/auth';
import theme from '@/theme';

export default function App({
  Component,
  pageProps: { session: sessionProp, ...pageProps },
}): JSX.Element {
  const getAuthToken = useCallback(async () => {
    const session = (await getSession()) as CustomSession;
    return session?.idToken || null;
  }, []);

  const apolloClient = useApollo(pageProps.initialApolloState, getAuthToken);

  return (
    <Sentry.ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <SessionProvider session={sessionProp}>
          <AuthProvider clearCache={() => apolloClient.clearStore()}>
            <ChakraProvider resetCSS theme={theme}>
              <>
                <Head>
                  <title>{config.title}</title>
                  <meta
                    content='width=device-width, initial-scale=1'
                    name='viewport'
                  />
                  <meta content='ie=edge' httpEquiv='x-ua-compatible' />
                </Head>
                <AnimatePresence exitBeforeEnter>
                  <Component {...pageProps} />
                </AnimatePresence>
              </>
            </ChakraProvider>
          </AuthProvider>
        </SessionProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}
