import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type CreateEventInput = {
  description?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  image?: InputMaybe<Scalars['Upload']>;
  location?: InputMaybe<PlaceInput>;
  name: Scalars['String'];
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type FileModel = {
  __typename?: 'FileModel';
  id: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type Geometry = {
  __typename?: 'Geometry';
  location: Location;
};

export type GeometryInput = {
  location: LocationInput;
};

export type GetUsersQueryInput = {
  email: Scalars['String'];
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['Float'];
  label: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type LocationInput = {
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  createdAt: Scalars['DateTime'];
  file?: Maybe<FileModel>;
  id: Scalars['Float'];
  type: MediaType;
};

export enum MediaType {
  EventCoverImage = 'EVENT_COVER_IMAGE'
}

export type Mutation = {
  __typename?: 'Mutation';
  createEvent: SocialEvent;
  deleteDeviceToken?: Maybe<Scalars['Boolean']>;
  forgotPassword: Scalars['Boolean'];
  login: UserResponse;
  register: UserResponse;
  resetPassword: Scalars['Boolean'];
  saveDeviceToken?: Maybe<Scalars['Boolean']>;
  updateProfile: User;
  updateUserRole: User;
};


export type MutationCreateEventArgs = {
  options: CreateEventInput;
};


export type MutationDeleteDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  options: LoginInput;
};


export type MutationRegisterArgs = {
  options: RegisterInput;
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSaveDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationUpdateProfileArgs = {
  options: UpdateProfileInput;
};


export type MutationUpdateUserRoleArgs = {
  options: UpdateUserRoleInput;
};

export type PagedMediaResponse = {
  __typename?: 'PagedMediaResponse';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<Media>;
  totalCount: Scalars['Float'];
};

export type PagedQueryInput = {
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export type PagedUsersResponse = {
  __typename?: 'PagedUsersResponse';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<UserModel>;
  totalCount: Scalars['Float'];
};

export type Place = {
  __typename?: 'Place';
  formattedAddress: Scalars['String'];
  geometry: Geometry;
  name: Scalars['String'];
};

export type PlaceInput = {
  formattedAddress: Scalars['String'];
  geometry: GeometryInput;
  name: Scalars['String'];
};

export type PublicUser = {
  __typename?: 'PublicUser';
  firstName: Scalars['String'];
  id: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  event: SocialEvent;
  items: Array<Item>;
  media: PagedMediaResponse;
  secretItems: Array<SecretItem>;
  self: UserModel;
  user: QueryUserResponse;
  users: PagedUsersResponse;
};


export type QueryEventArgs = {
  uid: Scalars['String'];
};


export type QueryMediaArgs = {
  eventId: Scalars['Float'];
  options?: InputMaybe<PagedQueryInput>;
};


export type QueryUserArgs = {
  options: QueryUserInput;
};


export type QueryUsersArgs = {
  options?: InputMaybe<GetUsersQueryInput>;
};

export type QueryUserInput = {
  id: Scalars['ID'];
};

export type QueryUserResponse = {
  __typename?: 'QueryUserResponse';
  error?: Maybe<Scalars['String']>;
  user?: Maybe<UserModel>;
};

export type RegisterInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  organisationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type SecretItem = {
  __typename?: 'SecretItem';
  description: Scalars['String'];
  id: Scalars['Float'];
  label: Scalars['String'];
};

export type SocialEvent = {
  __typename?: 'SocialEvent';
  coverImages: Array<Media>;
  description?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  location?: Maybe<Place>;
  name: Scalars['String'];
  owner?: Maybe<PublicUser>;
  ownerId: Scalars['Float'];
  toDate?: Maybe<Scalars['DateTime']>;
  uid: Scalars['String'];
};

export type UpdateProfileInput = {
  firstName: Scalars['String'];
};

export type UpdateUserRoleInput = {
  roles: Array<UserRole>;
  userId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  firebaseId: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
};

export type UserModel = {
  __typename?: 'UserModel';
  email?: Maybe<Scalars['String']>;
  firebaseId: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  error?: Maybe<Scalars['String']>;
  idToken?: Maybe<Scalars['String']>;
  user?: Maybe<UserModel>;
};

/** User Role */
export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type EventFieldsFragment = { __typename?: 'SocialEvent', id: number, name: string, description?: string | null, fromDate?: any | null, toDate?: any | null, owner?: { __typename?: 'PublicUser', id: number, firstName: string } | null, coverImages: Array<{ __typename?: 'Media', file?: { __typename?: 'FileModel', name: string, url: string } | null }> };

export type MediaFieldsFragment = { __typename?: 'Media', createdAt: any, file?: { __typename?: 'FileModel', name: string, url: string } | null };

export type CreateEventMutationVariables = Exact<{
  options: CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'SocialEvent', id: number, uid: string, name: string, description?: string | null, fromDate?: any | null, toDate?: any | null, owner?: { __typename?: 'PublicUser', id: number, firstName: string } | null } };

export type RegisterUserMutationVariables = Exact<{
  options: RegisterInput;
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', register: { __typename?: 'UserResponse', error?: string | null, idToken?: string | null, user?: { __typename?: 'UserModel', firstName: string, firebaseId: string } | null } };

export type EventQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type EventQuery = { __typename?: 'Query', event: { __typename?: 'SocialEvent', id: number, name: string, description?: string | null, fromDate?: any | null, toDate?: any | null, owner?: { __typename?: 'PublicUser', id: number, firstName: string } | null, coverImages: Array<{ __typename?: 'Media', file?: { __typename?: 'FileModel', name: string, url: string } | null }> } };

export type ItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemsQuery = { __typename?: 'Query', items: Array<{ __typename?: 'Item', id: number, label: string }> };

export type MediaQueryVariables = Exact<{
  eventId: Scalars['Float'];
  options: PagedQueryInput;
}>;


export type MediaQuery = { __typename?: 'Query', media: { __typename?: 'PagedMediaResponse', pageOffset: number, pageSize: number, totalCount: number, results: Array<{ __typename?: 'Media', createdAt: any, file?: { __typename?: 'FileModel', name: string, url: string } | null }> } };

export type SecretItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type SecretItemsQuery = { __typename?: 'Query', secretItems: Array<{ __typename?: 'SecretItem', id: number, label: string, description: string }> };

export type SelfQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfQuery = { __typename?: 'Query', self: { __typename?: 'UserModel', id: number, firstName: string, email?: string | null, firebaseId: string } };

export const EventFieldsFragmentDoc = gql`
    fragment EventFields on SocialEvent {
  id
  name
  description
  fromDate
  toDate
  owner {
    id
    firstName
  }
  coverImages {
    file {
      name
      url
    }
  }
}
    `;
export const MediaFieldsFragmentDoc = gql`
    fragment MediaFields on Media {
  createdAt
  file {
    name
    url
  }
}
    `;
export const CreateEventDocument = gql`
    mutation createEvent($options: CreateEventInput!) {
  createEvent(options: $options) {
    id
    uid
    name
    description
    fromDate
    toDate
    owner {
      id
      firstName
    }
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($options: RegisterInput!) {
  register(options: $options) {
    error
    idToken
    user {
      firstName
      firebaseId
    }
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const EventDocument = gql`
    query event($uid: String!) {
  event(uid: $uid) {
    ...EventFields
  }
}
    ${EventFieldsFragmentDoc}`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const ItemsDocument = gql`
    query items {
  items {
    id
    label
  }
}
    `;

/**
 * __useItemsQuery__
 *
 * To run a query within a React component, call `useItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useItemsQuery(baseOptions?: Apollo.QueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options);
      }
export function useItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options);
        }
export type ItemsQueryHookResult = ReturnType<typeof useItemsQuery>;
export type ItemsLazyQueryHookResult = ReturnType<typeof useItemsLazyQuery>;
export type ItemsQueryResult = Apollo.QueryResult<ItemsQuery, ItemsQueryVariables>;
export const MediaDocument = gql`
    query media($eventId: Float!, $options: PagedQueryInput!) {
  media(eventId: $eventId, options: $options) {
    pageOffset
    pageSize
    results {
      ...MediaFields
    }
    totalCount
  }
}
    ${MediaFieldsFragmentDoc}`;

/**
 * __useMediaQuery__
 *
 * To run a query within a React component, call `useMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useMediaQuery(baseOptions: Apollo.QueryHookOptions<MediaQuery, MediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options);
      }
export function useMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MediaQuery, MediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options);
        }
export type MediaQueryHookResult = ReturnType<typeof useMediaQuery>;
export type MediaLazyQueryHookResult = ReturnType<typeof useMediaLazyQuery>;
export type MediaQueryResult = Apollo.QueryResult<MediaQuery, MediaQueryVariables>;
export const SecretItemsDocument = gql`
    query secretItems {
  secretItems {
    id
    label
    description
  }
}
    `;

/**
 * __useSecretItemsQuery__
 *
 * To run a query within a React component, call `useSecretItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecretItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecretItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSecretItemsQuery(baseOptions?: Apollo.QueryHookOptions<SecretItemsQuery, SecretItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SecretItemsQuery, SecretItemsQueryVariables>(SecretItemsDocument, options);
      }
export function useSecretItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SecretItemsQuery, SecretItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SecretItemsQuery, SecretItemsQueryVariables>(SecretItemsDocument, options);
        }
export type SecretItemsQueryHookResult = ReturnType<typeof useSecretItemsQuery>;
export type SecretItemsLazyQueryHookResult = ReturnType<typeof useSecretItemsLazyQuery>;
export type SecretItemsQueryResult = Apollo.QueryResult<SecretItemsQuery, SecretItemsQueryVariables>;
export const SelfDocument = gql`
    query Self {
  self {
    id
    firstName
    email
    firebaseId
  }
}
    `;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
      }
export function useSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
        }
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;