import { extendTheme, Theme as DefaultTheme } from '@chakra-ui/react';

import components from './components';
import foundations from './foundations';
import styles from './styles';

/**
 * Color mode config
 */
const config: DefaultTheme['config'] = {
  useSystemColorMode: false,
  initialColorMode: 'light',
};

const base = {
  ...foundations,
  styles,
  components,
  config,
};

export type Theme = DefaultTheme & typeof base;

const theme = extendTheme(base) as Theme;

export default theme;
